<script setup lang="ts">
const props = defineProps<{
  modelValue: string
}>();

const emit = defineEmits(['update:modelValue']);

function updateIcon(Icon: string) {
  emit('update:modelValue', Icon);
}

const icons = [
  '',
  'i-fa6-solid:folder-open',
  'i-fa6-solid-folder',
  'i-fa6-solid-envelope',
  'i-fa6-solid-envelope-open',
  'i-fa6-solid-globe',
  'i-fa6-solid-magnifying-glass',
  'i-fa6-solid-computer',
  'i-fa6-solid-server',
  'i-fa6-solid-calendar',
  'i-fa6-solid-clock',
  'i-fa6-solid-phone',
  'i-fa6-solid-address-book',
  'i-fa6-solid-user',
  'i-fa6-solid-users',
  'i-fa6-solid-user-graduate',
  'i-fa6-solid-circle-user',
  'i-fa6-solid-house-user',
  'i-fa6-solid-ruler',
  'i-fa6-solid-house-chimney',
  'i-fa6-solid-cloud',
  'i-fa6-solid-wifi',
  'i-fa6-solid-book',
  'i-fa6-solid-book-open',
  'i-fa6-solid-feather',
  'i-fa6-solid-pen',
  'i-fa6-solid-pen-to-square',
  'i-fa6-solid-paper-plane',
  'i-fa6-solid-file-lines',
  'i-fa6-solid-paperclip',
  'i-fa6-solid-question',
  'i-fa6-solid-chalkboard',
  'i-fa6-solid-chalkboard-user',
  'i-fa6-solid-print',
  'i-fa6-solid-xmark',
  'i-fa6-solid-rotate',
  'i-fa6-solid-calendar-days',
  'i-fa6-solid-map',
  'i-fa6-solid-message',
  'i-logos-microsoft-teams',
  'i-custom-untis',
  'i-custom-its',
  'i-custom-moodle',
  'i-custom-nextcloud',
  'i-custom-seafile',
  'i-custom-office',
  'i-custom-ms365',
  'i-custom-moin',
  'i-custom-neo',
  'i-custom-fobizz',
  'i-logos-google-icon',
  'i-logos-google',
  'i-logos-google-cloud',
  'i-logos-google-drive',
  'i-logos-google-gmail',
  'i-logos-google-calendar',
  'i-logos-microsoft-windows',
  'i-vscode-icons-file-type-word',
  'i-vscode-icons-file-type-excel',
  'i-vscode-icons-file-type-powerpoint',
  'i-logos-microsoft-onedrive',
];
</script>

<template>
  <VDropdown placement="bottom-start" :arrow-padding="20">
    <div class="h-10 w-10 flex cursor-pointer items-center self-center justify-center border-1 rounded text-center text-4xl">
      <div :class="`w-7 h-7 ${props.modelValue}` " />
    </div>
    <template #popper="{ hide }">
      <div class="z-10 grid grid-cols-9 max-h-xs gap-1 overflow-y-scroll border border-gray-200 rounded bg-white p-3 pt-2 drop-shadow-xl">
        <div v-for="(icon) in icons" :key="icon" :class="`${icon} rounded text-center cursor-pointer text-3xl m-1 p-3 anim-scale-120`" @click="hide(); updateIcon(icon)" />
      </div>
    </template>
  </VDropdown>
</template>
