const __pages_import_0__ = () => import("/src/pages/waiting.vue");
const __pages_import_1__ = () => import("/src/pages/sso.vue");
const __pages_import_2__ = () => import("/src/pages/restricted.vue");
const __pages_import_3__ = () => import("/src/pages/reload.vue");
const __pages_import_4__ = () => import("/src/pages/logout.vue");
const __pages_import_5__ = () => import("/src/pages/login.vue");
import __pages_import_6__ from "/src/pages/index.vue";
const __pages_import_7__ = () => import("/src/pages/help.vue");
const __pages_import_8__ = () => import("/src/pages/extern.vue");
const __pages_import_9__ = () => import("/src/pages/admin.vue");

const routes = [{"name":"waiting","path":"/waiting","component":__pages_import_0__,"props":true,"meta":{"layout":"simple"}},{"name":"sso","path":"/sso","component":__pages_import_1__,"props":true,"meta":{"layout":"simple"}},{"name":"restricted","path":"/restricted","component":__pages_import_2__,"props":true,"meta":{"layout":"simple"}},{"name":"reload","path":"/reload","component":__pages_import_3__,"props":true,"meta":{"layout":"simple"}},{"name":"logout","path":"/logout","component":__pages_import_4__,"props":true},{"name":"login","path":"/login","component":__pages_import_5__,"props":true},{"name":"index","path":"/","component":__pages_import_6__,"props":true},{"name":"help","path":"/help","component":__pages_import_7__,"props":true},{"name":"extern","path":"/extern","component":__pages_import_8__,"props":true,"meta":{"layout":"extern"}},{"name":"admin","path":"/admin","component":__pages_import_9__,"props":true}];

export default routes;