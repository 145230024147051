<script setup lang="ts">
import { useRouter } from 'vue-router';
import type { ent_App } from '~/api/generated';
import { useAppStore } from '~/stores/app';
import { AppService } from '~/api/generated';
import { useCachePasswordModal } from '~/utils/cachePasswordModal';
import { useUserStore } from '~/stores/user';

interface Props {
  app: ent_App
  editMode: boolean
  showTitle?: boolean
  indicator?: string
}

const props = withDefaults(defineProps<Props>(), {
  showTitle: true,
  editMode: false,
  indicator: ''
});

const emit = defineEmits<{
  (e: 'click'): void
}>();

const { openCachePasswordModal } = useCachePasswordModal();

const appStore = useAppStore();
const router = useRouter();

const userStore = useUserStore();

const url = ref<string>(props.app.url as string);
const app = ref<string>('Link');

const indicator = ref<string>(props.indicator);

// Set app name
if (props.app.appTypeId !== 0)
  app.value = props.app.edges?.app_type?.name as string;

const href = computed(() => {
  if (props.app.iframe || url.value === '#' || props.editMode || app.value === 'Guacamole')
    return '';

  return 'href';
});

const download = computed(() => {
  if (!props.app.download || props.editMode)
    return '';

  return 'download';
});

const t = '_blank';
const target = computed(() => {
  if (!props.app.external || props.editMode)
    return '';

  return 'target';
});

function getFileFromURL(url: string) {
  const parts = url.split('/');
  return parts[parts.length - 1];
}

function setIframeMode(url: string) {
  appStore.iframeMode! = true;
  appStore.iframeURL! = url;
  router.push('/extern');
}

function calcDpi() {
  let dpi = 96;
  if (window.matchMedia('(min-resolution: 10dpi)').matches) {
    for (let i = 650; i >= 1; i = (i - 1)) {
      if (window.matchMedia(`(min-resolution: ${i}dpi)`).matches) {
        dpi = i;
        break;
      }
    }
  }
  return dpi;
}

async function click() {
  if (props.editMode) {
    emit('click');
    return;
  }

  // Guacamole
  if (app.value === 'Guacamole') {
    const json = JSON.parse(props.app.edges?.app_type?.config as string);
    try {
      url.value = await AppService.redirectGuacamole(json.Name,await userStore.getToken() , calcDpi().toString(),window.screen.width.toString(),window.screen.height.toString());
    }
    catch {
      url.value = '/restricted';
    }

    if (url.value === 'No password found') {
      openCachePasswordModal();
      return;
    }

    if (!props.app.iframe) {
      if (props.app.external)
        window.open(url.value, '_blank');
      else
        window.location.href = url.value;
    }
    else {
      appStore.showFullscreenModeButton = true;
    }
  }

  // Virtuelles Klassenbuch
  if (app.value === 'Virtuelles Klassenbuch') {
    try {
      if (props.app.iframe)
        setIframeMode('/waiting');
      url.value = await AppService.redirectVirtuellesKlassenbuch();
    }
    catch {
      url.value = '/restricted';
    }

    if (!props.app.iframe) {
      if (props.app.external)
        window.open(url.value, '_blank');
      else
        window.location.href = url.value;
    }
  }

  // Virtueller Stundenplan
  if (app.value === 'Virtueller Stundenplan') {
    try {
      if (props.app.iframe)
        setIframeMode('/waiting');
      url.value = await AppService.redirectVirtuellerStundenplan();
    }
    catch {
      url.value = '/restricted';
    }

    if (!props.app.iframe) {
      if (props.app.external)
        window.open(url.value, '_blank');
      else
        window.location.href = url.value;
    }
  }

  if (props.app.iframe) {
    if ((navigator.userAgent.match(/(iPod|iPhone|iPad)/) || navigator.userAgent.includes('Mac')) && ((app.value === 'Virtueller Stundenplan') || app.value === 'Virtuelles Klassenbuch')) {
      window.location.href = url.value;
      return;
    }
    setIframeMode(url.value);
  }
}

async function getMailCount() {
  try {
    indicator.value = await AppService.getNewMailCount();
  }
  catch {
    indicator.value = '0';
  }
}

if (app.value === 'E-Mail') {
  getMailCount();
}
</script>

<template>
  <a
    :[href]="url"
    :[target]="t"
    :[download]="getFileFromURL(url as string)"
    :class="'h-full ' +(url as string) !== '#'
      ? 'cursor-pointer text-black hover:text-black hover:no-underline'
      : 'text-black hover:text-black hover:no-underline'"
    @click="click()"
  >
    <div class="h-full w-25 flex flex-col md:w-33 anim-scale-95">
      <div
        :class="`${props.app.color} rounded-full h-15 w-15 md:h-20 md:w-20 drop-shadow-lg self-center flex items-center justify-center text-center`"
      >
        <div :class="`${props.app.iconName} text-3xl ${props.app.iconColor}`" />
        <div v-if="indicator !== '' && indicator !== '0'" class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-0.5 -end-1">
          {{ indicator }}
        </div>
      </div>
      <div v-if="props.showTitle" class="line-clamp-2 pt-2 text-center mx-auto drop-shadow-lg" :title="props.app.title">
        <div class="text-on-bg">{{ props.app.title }}</div>
      </div>
    </div>
  </a>
</template>
